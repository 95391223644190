/* Authentication.css */
.registration-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.registration-form {
    margin-top: 20px;
}

.registration-form label {
    display: block;
    margin-bottom: 10px;
}

.registration-form select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
}

.register-button {
    background-color: #e63029;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
}

.register-button:hover {
    background-color: #cf2a20; /* A slightly darker shade for hover effect */
}
  