.hero-section {
    display: flex;
    align-items: center;
    padding: 4rem 0; /* Add padding top and bottom for spacing */
    background-color: #fff; /* or any color that matches your design */
}

.hero-image {
    flex: 1; /* Flex value to split space evenly */
    max-width: 50%; /* Set a max-width to control the image size */
    padding-right: 2rem; /* Add some padding between the image and the text */
}

.hero-image img {
    width: 70%; /* Make image responsive */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px; /* Optional for rounded corners */
}

.hero-content {
    flex: 1; /* Flex value to split space evenly */
    max-width: 50%; /* Set a max-width to control content width */
    text-align: left; /* Align text to the left */
}

.hero-content h1 {
    margin-bottom: 1rem;
    color: #333; /* Dark grey for text */
    font-size: 2.4rem; /* Larger font size for headings */
}

.hero-content p {
    margin-bottom: 1rem;
    line-height: 1.6;
    color: #555; /* Light grey for paragraph */
}

.hero-content button {
    background-color: #e63029; /* Theme color for the button */
    color: white;
    border: none;
    padding: 1rem 2rem;
    cursor: pointer;
    border-radius: 4px;
    font-weight: bold;
    text-transform: uppercase; /* Uppercase text for the button */
    letter-spacing: 1px; /* Add some spacing between letters */
}

.hero-content button:hover {
    background-color: #cf2a20; /* Darker shade for hover state */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .hero-section {
        flex-direction: column-reverse; /* Stack content on top of image */
    }

    .hero-image,
    .hero-content {
        max-width: 100%; /* Full width for smaller screens */
        padding-right: 0; /* Remove right padding on smaller screens */
        text-align: center; /* Center text for smaller screens */
    }

    .hero-content {
        padding-bottom: 2rem; /* Add padding below the content block */
    }
}
  