.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px; /* Adjust as needed */
    background-color: #e63029; /* Assuming red is your theme color */
    color: white;
    height: 50px; /* Set a fixed height for the header */
}

.header-logo {
    justify-content: flex-start;
    display: flex;
    height: 110%;
    /* Ensure your logo maintains aspect ratio */
}

.header-nav {
    position: absolute;
    left: 50%;
    transform: translateX(-50%); /* Perfectly centers nav */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    gap: 20px;
}

.header-nav a {
    color: white; /* Or any other color based on your design */
    text-decoration: none;
}

.header-buttons {
    display: flex;
    gap: 10px; /* Adjust the space between the buttons */
}

.button {
    padding: 10px 20px;
    border: none;
    border-radius: 20px; /* Rounded corners for buttons */
    background: white; /* Fallback color */
    color: #e63029; /* Text color */
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
}

.button:hover {
    background-color: #e63029; /* A darker shade for hover */
    color: white;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .header-nav {
        display: none; /* Optionally hide navigation on smaller screens */
    }

    .header {
        justify-content: space-between;
    }
}

/* Profile Button Styling */
.profile-button {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: white; /* Matches other buttons */
    color: #e63029;
    font-size: 1.5rem;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none; /* Remove link styling */
}

.profile-button:hover {
    background-color: #e63029; /* Match hover effect of other buttons */
    color: white;
}

/* Adjust icon inside the profile button */
.profile-icon {
    font-size: 1.4rem;
}

/* Ensure header buttons align properly */
.header-buttons {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between buttons */
}

