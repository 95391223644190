.wwd-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem; /* Adjust gap between the boxes if needed */
    padding: 2rem;
}

.wwd-item {
    flex: 1; /* Allows the box to grow */
    min-width: 250px; /* Minimum width of the boxes */
    max-width: calc(33.333% - 4rem); /* Subtracting total horizontal gap */
    padding: 1rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
}

.wwd-item img {
    width: 100px; /* Increase the size of the icons */
    margin-bottom: 1rem;
}

.wwd-item h3 {
    margin-bottom: 0.5rem;
    font-size: 1.3rem; /* Increase the size of the title */
}

.wwd-item p {
    font-size: 1.1rem; /* Increase the size of the paragraph */
    text-align: center;
    padding: 0 1rem; /* Add padding for text alignment */
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .wwd-item {
        min-width: 250px;
        max-width: calc(50% - 4rem); /* Two items per row */
    }
}

@media (max-width: 768px) {
    .wwd-item {
        min-width: 250px;
        max-width: calc(100% - 4rem); /* One item per row */
    }
}
  