/* Overlay (greyed-out background) */
.modal-overlay {
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

/* Modal content (popup) */
.modal-content {
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    max-width: 500px;
    width: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    animation: slideDown 0.2s ease-out;
}

/* Simple animation */
@keyframes slideDown {
    from {
        transform: translateY(-20%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Modal header */
.modal-header h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
}

/* Modal body */
.modal-body {
    margin-top: 1rem;
    font-size: 1rem;
    color: #555;
}

/* Modal footer */
.modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
}

.close-button {
    background-color: #e63029;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.close-button:hover {
    background-color: #cf2a20;
}
