.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh; /* Take full viewport height */
    padding: 2rem;
    background-color: #ffffff; /* Adjust background as needed */
}

.sign-in-form {
    width: 100%;
    max-width: 500px; /* Maximum width of the form */
    background-color: #ffffff; /* Form background */
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.form-group {
    margin-bottom: 1rem; /* Spacing between form groups */
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #333; /* Dark grey for text */
    font-size: 1rem; /* Larger font size */
}

.form-group input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem; /* Larger font size for inputs */
}

.sign-in-button {
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    background-color: #e63029; /* Theme color for button */
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition for hover */
}

.sign-in-button:hover {
    background-color: #cf2a20; /* Darken button on hover */
}

.loading-animation {
    text-align: center;
    color: #333;
}

.forgot-password-button {
    width: 100%;
    padding: 0.75rem;
    border: 2px solid #e63029; /* Red border to match theme */
    border-radius: 4px;
    background-color: #ffffff; /* White background */
    color: #e63029; /* Red text color */
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover */
    margin-top: 0.75rem; /* Add space above the button */
}

.forgot-password-button:hover {
    background-color: #e63029; /* Change background to red on hover */
    color: #ffffff; /* Change text color to white on hover */
}
  