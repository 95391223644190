/* General Footer Styling */
.footer {
    background-color: #f5f5f5; /* Light gray background */
    color: #333; /* Dark gray text */
    padding: 40px 0;
    font-family: 'Open Sans', sans-serif;
    text-align: center; /* Center text content */
}

/* Centering Content */
.footer-content {
    max-width: 900px; /* Limit width */
    margin: 0 auto; /* Center horizontally */
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 40px;
}

/* Footer Section Styling */
.footer-section {
    flex: 1;
    text-align: center; /* Center text inside each section */
}

/* Logo */
.footer-logo {
    width: 150px;
    margin-bottom: 15px;
}

/* Description */
.footer-description {
    font-size: 0.95rem;
    line-height: 1.6;
    margin-bottom: 15px;
}

/* Quick Links */
.footer-section h3 {
    font-size: 1rem;
    color: #777;
    margin-bottom: 10px;
}

.footer-section a {
    display: block;
    color: #e63029; /* Red theme */
    text-decoration: none;
    margin-bottom: 5px;
    font-size: 0.95rem;
}

.footer-section a:hover {
    text-decoration: underline;
}

/* Footer Bottom Section */
.footer-bottom {
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #ddd;
    padding-top: 15px;
    font-size: 0.9rem;
}

/* Copyright Text */
.footer-bottom p {
    color: #777;
    text-align: center;
    flex: 1;
}

/* Footer Bottom Links */
.footer-links {
    text-align: right;
}

.footer-links a {
    color: #e63029;
    text-decoration: none;
    margin-left: 15px;
}

.footer-links a:hover {
    text-decoration: underline;
}

/* Responsive Styling */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        text-align: center;
        gap: 20px;
    }

    .footer-bottom {
        flex-direction: column;
        text-align: center;
    }

    .footer-links {
        margin-top: 10px;
    }
}
