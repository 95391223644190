.contact-container {
    display: flex;
    justify-content: space-between;
    margin: 20px;
    padding: 20px;
}

.contact-info, .contact-form {
    width: 48%; /* Makes both sections take up roughly half the page */
}

.info-group h3 {
    margin-top: 10px;
    color: #e63029; /* Use the theme red for subheadings */
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 10px;
    resize: vertical;
    margin: 10px 0;
    border: 1px solid #ccc; /* Light grey border */
    border-radius: 4px; /* Slightly round the borders */
}

.contact-form button {
    background-color: #e63029; /* Use the theme red for the button */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.contact-form button:hover {
    background-color: #cc2921; /* A darker shade for hover state */
}

/* Responsive design adjustments */
@media (max-width: 768px) {
    .contact-container {
        flex-direction: column;
    }

    .contact-info, .contact-form {
        width: 100%; /* Full width on smaller screens */
    }
}
