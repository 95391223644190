/* MapComponent.css */
.map-and-filter-container {
    display: flex;
    justify-content: space-between;
}

.filter-sidebar {
    flex-basis: 20%;
    padding: 1em;
    border-left: 1px solid #ccc; /* Just a line to separate from map */
    background-color: transparent;
}

.checkbox-label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.filter-title {
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 20px; /* Space below the title */
}

.checkbox-wrapper-22 {
    display: flex;
    align-items: center;
}

.checkbox-wrapper-22 .switch {
    margin-right: 10px; /* Adjust space between switch and label */
}

.checkbox-wrapper-22 .switch {
    display: inline-block;
    height: 34px;
    position: relative;
    width: 60px;
}

.checkbox-wrapper-22 .switch input {
    display: none;
}

.checkbox-wrapper-22 .slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
}

.checkbox-wrapper-22 .slider:before {
    background-color: #fff;
    bottom: 4px;
    content: "";
    height: 26px;
    left: 4px;
    position: absolute;
    transition: .4s;
    width: 26px;
}

.checkbox-wrapper-22 input:checked + .slider {
    background-color: #66bb6a;
}

.checkbox-wrapper-22 input:checked + .slider:before {
    transform: translateX(26px);
}

.checkbox-wrapper-22 .slider.round {
    border-radius: 34px;
}

.checkbox-wrapper-22 .slider.round:before {
    border-radius: 50%;
}

.popup-content p {
    margin: 0;
    padding: 4px;
    font-size: 0.9rem; /* Adjust size as needed */
}

.popup-content p strong {
    font-weight: bold;
}

.rc-slider-track {
    background-color: #e63029; /* Change this to your desired track color */
}

.rc-slider-handle {
    border: 2px solid #e63029; /* Change this to your desired handle color */
}

.rc-slider {
    margin-top: 10px;
    width: 80%; /* Adjust the width of the slider */
    margin-left: auto;
    margin-right: auto;
}

/* Slider Styling */
.slider-label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
}

.slider-input {
    width: 100%;
    margin-top: 5px;
}

/* Dropdown Styling */
.dropdown {
    position: relative;
    margin-top: 15px;
}

.dropdown-button {
    width: 100%;
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    text-align: left;
}

.dropdown-button:hover {
    background-color: #f0f0f0;
}

.dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;
}

.dropdown-item {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: #f8f8f8;
}

.dropdown-item input {
    margin-right: 10px;
}

.services-select__menu {
    z-index: 9999 !important;
    position: relative !important;
}

.ambulatory-toggle {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
}

.ambulatory-button {
    padding: 8px 15px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background 0.3s ease;
    font-weight: bold;
    color: white;
    background-color: #ccc;
}

.ambulatory-button.selected {
    background-color: #e63029; /* Highlight selected button */
}

/* Dropdown button styling */
.dropdown-button {
    width: 100%;
    padding: 10px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    text-align: left;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Dropdown container */
.dropdown {
    position: relative;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
    position: absolute;
    width: 100%;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    max-height: 200px; /* Limit height */
    overflow-y: auto; /* Enable scrolling */
    z-index: 1000;
}

/* Style each dropdown item */
.dropdown-item {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 0.95rem;
}

/* Checkbox spacing */
.dropdown-item input {
    margin-right: 10px;
}

/* Hover effect */
.dropdown-item:hover {
    background-color: #f0f0f0;
}

/* Popup content styling */
.popup-content {
    max-height: 500px; /* Set a fixed height */
    max-width: 300px; /* Set a fixed width */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Hide horizontal overflow */
    padding: 10px; /* Add some padding */
    box-sizing: border-box; /* Ensure padding is included in the width/height */
}





  